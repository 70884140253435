@charset "UTF-8";
@import '../_mixin';
@import '../_config';

@import url('https://fonts.googleapis.com/css2?family=M+PLUS+1p&family=Noto+Serif+JP:wght@200;300;400;500;600;700;900&display=swap');

/* reset */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
  display: block;
}

ol, ul {list-style: none;}

blockquote, q {quotes: none;}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

address {
  font-style: normal;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  vertical-align: bottom;
  max-width: 100%;
  height: auto;
}

/* border-box */
*, *:before, *:after {
  box-sizing: border-box;
}

/* common */
html,body {height: 100%;}
html { font-size: 62.5%; }
body {
  color: $black_color;
  font-weight: normal;
  line-height: 1.5;  
  font-size: 1.6rem;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  font-family: $noto_serif;
}
h1,h2,h3,h4,h5,h6,
table th {
  font-weight: 300;
}

a,
._hover, {
  color: inherit;
  transition: opacity 0.3s;
  text-decoration: none;
  cursor: pointer;
}

.link_disabled {
  pointer-events: none;
}
img {
  //width: 100%;
}

a:hover,
._hover:hover {
  opacity: 0.6;
}


button{
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
}

input[type="submit"] {
	-webkit-appearance: none;
	border-radius: 0;
}


._pcOnly {
  display: block;
}
._spOnly {
  display: none;
}

@include sp {
  ._pcOnly {
    display: none;
  }
  ._spOnly {
    display: block;
  }
}

/*====================================================================
 COMMON BACKGROUND CSS
====================================================================*/
section {
  &._cmn-beige {
    background: $primary_bg_color;
  }
  &._rievenhouse {
    background: #F3F7ED;
  }
}


/*====================================================================
 TEXT COLOR CSS
====================================================================*/
.text_accent_color {
  color: $accent_color;
}


/*====================================================================
 LAYOUTS CSS
====================================================================*/
.l_inner {
  max-width: 1140px;
  margin: 0 auto;
  padding-left: var(--size-30);
  padding-right: var(--size-30);
  position: relative;
  @include sp {
    padding-left: var(--size-40);
    padding-right: var(--size-40);
  }
  &._fullwidth {
    max-width: initial;
    padding-left: 0;
    padding-right: 0;
  }
  &._flex {
    display: flex;
    position: relative;
    @include sp {
      display: block;
    }
  }
}


/*====================================================================
 TITLE COMMON CSS
====================================================================*/
.c_section_title {
  text-align: center;
  display: inline-block;
  .c_title {
    font-size: 1.6rem;
    font-family: $noto_sans;
    margin-top: var(--size-10);
    @include sp {
      font-size: get_vw(24);
    }
    &._en {
      font-family: $lora_font;
      font-size: 5.8rem;
      position: relative;
      line-height: 1;
      color: #DDDDDD;
      margin-top: 0;
      padding-bottom: var(--size-10);
      @include sp {
        font-size: get_vw(84);
      }
      &:before {
        content: '';
        display: block;
        background: #7162A0;
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 0;
      }
      &._catering {
        &:before {
          background: #7162A0;
        }
      }
      &._conveni {
        &:before {
          background: #6CA06E;
        }
      }
      &._restaurent {
        &:before {
          background: #DDA54B;
        }
      }
    }
  }
  &._catering {
    .c_title {
      color: $accent_color;
      font-size: 1.2rem;
    }
    .c_title._en {
      color: #151431;
      font-size: 4.6rem;
      padding-bottom: 0;
      &:before {
        display: none;
      }
    }
  }
}

.c_sec_title {
  text-align: center;
  display: inline-block;
  .c_title_en {
    font-size: 4.6rem;
    font-family: $lora_font;
    color: #2B2738;
    line-height: 1;
    letter-spacing: 0.2rem;
    @include sp {
      font-size: get_vw(72)
    }
  }
  .c_title_ja {
    font-size: 1.2rem;
    font-family: $noto_sans;
    line-height: 1;
    margin-top: var(--size-10);
    color: $accent_color;
    letter-spacing: 0.1rem;
    @include sp {
      font-size: get_vw(24)
    }
  }
  // option
  &._left {
    text-align: left;
  }
  &._brown {
    .c_title_en, .c_title_ja {
      color: $accent_color;
    }
  }
  &._gray {
    .c_title_en, .c_title_ja {
      color: #858589;
    }
  }
  &._white {
    .c_title_en, .c_title_ja {
      color: $white_color;
    }
  }
  &._type02 {
    .c_title_en {
      color: $white_color;
    }
  }
  // rsc
  &._rsc {
    &._white {
      .c_title_en {
        color: $white_color;
      }
      .c_title_ja {
        color: $rsc_brown;
      }
      &._type02 {
        .c_title_ja {
          color: $white_color;
        }
      }
    } 
    &._orange {
      .c_title_en {
        color: $rsc_primary;
      }
      .c_title_ja {
        color: $rsc_brown;
      }
    } 
    .c_title_en {
      font-family: $mont_font;
      color:$black_color;
      font-size: 5.2rem;
      @include font-weight(bold);
      @include sp {
        font-size: get_vw(80);
      }
    }
    .c_title_ja {
      color: $black_color;
      font-size: 1.6rem;
      @include font-weight(bold);
      margin-top: var(--size-5);
      @include sp {
        font-size: get_vw(28);
      }
    }
  }
}

.heading {
  font-family: $noto_serif;
  text-align: center;
  &._type01 {
    font-size: 2.8rem;
    @include font-weight(medium);
    @include sp {
      font-size: get_vw(48);
    }
  }
  &._type02 {
    font-size: 2.4rem;
    @include font-weight(medium);
    @include sp {
      font-size: get_vw(44);
    }

  }
  &._type03 {
    font-size: 2.2rem;
    @include font-weight(semi);
  }
  &._type04 {
    font-family: $noto_sans;
    font-size: 1.8rem;
    @include font-weight(bold);
  }
}

.c_text {
  font-family: $noto_sans;
  font-size: 1.6rem;
  @include sp {
    font-size: get_vw(32);
  }
  a {
    text-decoration: underline;
  }
  &._brown {
    color: $accent_color;
  }
}

.c_note {
  font-family: $noto_sans;
  font-size: 1rem;
}

/*====================================================================
 UNDER LAYER COMMON CSS
====================================================================*/
.c_ul_heading {
  height: 200px;
  background-position: center;
  @include sp {
    .l_inner {
      padding-left: var(--size-40);
      padding-right: var(--size-40);
      height: var(--size-270);
      position: relative;
    }
    height: auto;
  }
  &._about {
    @include pseudo-elements('../../img/about/about_top.png');
    background-size: cover;
  }
  &._privacy {
    @include pseudo-elements('../../img/privacy/privacy_top.png');
    background-size: cover;
    @include sp {
      @include pseudo-elements('../../img/privacy/privacy_top_sp.png');
    }
  }  
  &._news {
    @include pseudo-elements('../../img/news/news_top.png');
    background-size: cover;
    @include sp {
      @include pseudo-elements('../../img/news/news_top_sp.png');
    }
  }
  &._hall {
    @include pseudo-elements('../../img/hall/hall_top.png');
    background-size: cover;
  }  
  &._option {
    @include pseudo-elements('../../img/option/option_top.png');
    background-size: cover;
  }  
  &._example {
    @include pseudo-elements('../../img/example/example_top.png');
    background-size: cover;
  }  
  &._guide {
    @include pseudo-elements('../../img/guide/guide_top.png');
    background-size: cover;
    @include sp {
      @include pseudo-elements('../../img/guide/guide_top_sp.png');
      background-size: 101%;
    }
  }  
  &._planlist {
    @include pseudo-elements('../../img/planlist/planlist_top.png');
    background-size: cover;
    @include sp {
      @include pseudo-elements('../../img/planlist/planlist_top_sp.png');
      background-size: 101%;
    }
  } 
  &._lunchbox {
    @include pseudo-elements('../../img/lunchbox/lunchbox_top.png');
    background-size: cover;
  }   
  &._drink {
    @include pseudo-elements('../../img/drink/drink_top.png');
    background-size: cover;
  }   
  &._contact {
    @include pseudo-elements('../../img/contact/contact_top.png');
    background-size: cover;
    @include sp {
      @include pseudo-elements('../../img/contact/contact_top_sp.png');
    }
  }
  &._rievenhouse-topics {
    @include pseudo-elements('../../img/rievenhouse-topics/rievenhouse_topics_top.png');
    background-size: cover;
  }   
  &._notfound {
    @include pseudo-elements('../../img/notfound/notfound_top.png');
    background-size: cover;
    @include sp {
      @include pseudo-elements('../../img/notfound/notfound_top_sp.png');
    }
  }  
  &._maintenance {
    @include pseudo-elements('../../img/maintenance/maintenance_top.png');
    background-size: cover;
    @include sp {
      @include pseudo-elements('../../img/maintenance/maintenance_top_sp.png');
    }
  }  
}

.c_ul_title_wrap {
  text-align: center;
  padding-top: var(--size-45);
  @include sp {
    padding-top: initial;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    position: absolute;
    width: 90%;
  }
  &._type02 {
    text-align: left;
    .c_ul_title {
      &._en {
        opacity: 0.7;
        font-size: 4.6rem;
        @include sp {
          font-size:get_vw(64);
          letter-spacing: 0;
        }
      }
      &._ja {
        h1 {
          font-size: 2rem;
          @include font-weight(bold);
          @include sp {
            font-size: get_vw(28);
          }
        }
      }
    }
  }
  .c_ul_title {
    &._en {
      color: $white_color;
      opacity: .2;
      font-family: $lora_font;
      font-size: 5.6rem;
      line-height: 1;
      padding-bottom: var(--size-10);
      letter-spacing: 0.15rem;
      &._type02 {
        font-family: $noto_sans;
        @include font-weight(bold);
      }
      @include sp {
        font-size: get_vw(64);
      }
    }
    &._ja {    
      font-family: $noto_sans;
      h1 {
        font-size: 1.6rem;
        color: $white_color;
        line-height: 1;
        @include font-weight(medium); 
        @include sp {
          font-size: get_vw(28);
        }
      }
    }
  }
}

// c_breadcrumb
.c_breadcrumb {
  color: $white_color;
  font-family: $noto_sans;
  font-size: 1rem;
  display: flex;
  padding-top: var(--size-15);
  @include sp {
    display: none;
  }
  .item {
    display: flex;
    align-items: center;
    a {
      text-decoration: underline;
    }
    &:after {
      content: '/';
      display: block;
      margin-left: var(--size-10);
    }
    &._current {
      &:after {
        display: none;
      }
    }
  }
  .item + .item {
    margin-left: var(--size-10);
  }
}

.c_ul_sec_title {
  text-align: center;
  .title {
    font-size: 2.8rem;
    text-align: center;
    line-height: 1;
    position: relative;
    display: inline-block;
    @include font-weight(medium);
    letter-spacing: 0.4rem;
    font-family: $noto_serif;
    @include sp {
      font-size: get_vw(48);
      letter-spacing: -0.01rem;
      line-height: 1.4;
    }
    &:before {
      content: '';
      display: block;
      background-color: $accent_color;
      width: 28px;
      height: 2px;
      margin: 0 auto var(--size-20);
    }
    &._notosans {
      font-family: $noto_sans;
    }
  }
  &._none {
    .title {
      &:before {
        display: none;
      }
    }
  }
}

.c_ul_under_title {
  border-bottom: 1px solid $accent_color;
  .title {
    font-family: $noto_serif;
    font-size: 2.2rem;
    line-height: 1;
    padding-bottom: var(--size-10);
    @include sp {
      font-size: get_vw(44);
    }
  }
}


/*====================================================================
 BUTTON CSS
====================================================================*/
.c_btn {
  .c_text {
    @include sp {
      font-size: get_vw(28);
    }
  }
  display: inline-block;
  a,input[type=submit] {
    border-width: 0;
    background: $accent_color;
    display: block;
    font-family: $noto_sans;
    color: $white_color;
    font-size: 1.4rem;
    padding: var(--size-20) 0;
    width: 252px;
    height: 64px;
    text-align: center;
    transition: .5s;
    box-sizing: border-box;
    letter-spacing: 0.1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @include sp {
      @include p-vw(height, 128);
      @include p-vw(width, 444);
      font-size: get_vw(28);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &:hover {
      background: $white_color;
      border: 1px solid $accent_color;
      color: $accent_color;
      opacity: 1;
      transition: .5s;
    }
    &:active {
      background: $black_color;
      color: $white_color;
      opacity: 1;
      transition: .5s;
    }
    
    &:disabled,
    &:disabled:hover {
      background: #d8c9b4;
      border: none;
      color: #FFF;
    }
  }
  &._back {
    a {
      background: $line_color;
      color: $black_color;
      &:hover {
        background: none;
        color: $black_color;
        border: 1px solid $black_color;
      }
    }
  }
  &._bold {
    a {
      @include font-weight(bold);
    }
  }
  &._green {
    a {
      background: #334644;
      &:hover {
        border: 1px solid #334644;
        background: #FFF;
        color: #334644;
      }
    }
  }
  &._blank {
    position: relative;
    a:after {
      @include pseudo-elements('../../img/cmn/icon_blank_white.svg');
      width: 16px;
      height: 13px;
      background-size: contain;
      position: absolute;
      transform: translate(-50%, -50%);
      top : 50%;
      right: 2px;
    }
    a:hover:after {
      @include pseudo-elements('../../img/cmn/icon_blank_brown.svg');
    }
  }
}

.c_btn_more {
  text-align: right;
  a {
    font-size: 1.4rem;
    display: inline-block;
    padding: var(--size-15) var(--size-35) var(--size-15) var(--size-35);
    position: relative;
    line-height: 1;
    letter-spacing: 0.3rem;
    font-family: $noto_serif;
    @include sp {
      font-size: get_vw(32);
      padding: var(--size-30) var(--size-100) var(--size-30) var(--size-65);
    }
    &:before, &:after {
      content: '';
      display: block;
      height: 100%;
      width: 2px;
      background: $accent_color;
      opacity: 0.5;
      position: absolute;
      top: 0;
      left: 0;
    }
    &:after {
      height: 0;
      transition: .5s;
      opacity: 1;
      top: initial;
      bottom: 0;
    }
    &:hover:after {
      height: 100%;
      transition: .5s;
      opacity: 1;
    }
    .arrow {
      &:after {
        @include pseudo-elements('../../img/cmn/right_arrow.svg');
        width: 20px;
        height: 6px;
        position: absolute;
        transform: translate(-50%, -50%);
        right: -10px;
        top: 50%;
        @include sp {
          @include p-vw(height, 16);
          @include p-vw(width, 56);
          background-size: contain;
          right: calc(-1 * (30 / 750 * 100vw));
        }
      }
    }
  }
  &._gray {
    a {
      &:before, &:after {
        background: #151431;
      }
    }
  }
  &._white {
    a {
      color: #FFF;
      .arrow {
        &:after {
          @include pseudo-elements('../../img/cmn/right_arrow_white.svg');  
        }
      }
    }
  }
}

// rsc btn
.c_rsc_btn {
  display: inline-block;
  a {
    background: $rsc_brown;
    font-size: 1.6rem;
    font-family: $noto_sans;
    @include font-weight(bold);
    color: $white_color;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--size-15) var(--size-25) var(--size-15) var(--size-40);
    border-radius: var(--size-30);
    line-height: 1;
    position: relative;
    @include sp {
      font-size: get_vw(32);
      @include p-vw(height, 112);
      @include p-vw(width, 504);
      @include p-vw(border-radius, 56);
      @include p-vw(text-indent, -60);
    }
    &:after {
      @include pseudo-elements('../../img/cmn/icon_rsc_btn_arrow_white.svg');
      width: var(--size-30);
      height: var(--size-30);
      margin-left: var(--size-15);
      @include sp {
        width: var(--size-60);
        height: var(--size-60);
        transform: translate(-50%, -50%);
        top: 50%;
        position: absolute;
        right: var(--size-10);
      }
    }
    
  }
  
  &._white {
    @include sp {
      width: 100%;
    }
    .c_sub_text {
      color: $white_color;
      font-size: 1.6rem;
      margin-bottom: var(--size-10);
      font-family: $noto_sans;
      @include sp {
        font-size: get_vw(32);
      }
    }
    a {
      flex-wrap: wrap;
      width: 436px;
      background: $white_color;
      color: $rsc_primary;
      border-radius: 6px;
      font-size: 2.2rem;
      color: $rsc_brown;
      height: var(--size-100);
      position: relative;
      padding: var(--size-15) 0;
      line-height: 1.4;
      text-indent: initial;
      .c_tel_wrap {
        .num {
          line-height: 1;
          letter-spacing: 0.1rem;
        }
      }
      @include sp {
        width: 100%;
        font-size: get_vw(32);
        height: var(--size-160);
      }
      &:after {
        @include pseudo-elements('../../img/cmn/icon_rsc_btn_arrow_orange.svg');
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        right: 5px;
      }
      .num {
        color: $rsc_primary;
        width: 100%;
        font-size: 3.6rem;
        @include font-weight(bold);
        @include sp {
          font-size: get_vw(60);
        }
      }
      .note {
        display: block;
        font-size: 1.4rem;
        @include sp {
          font-size: get_vw(22);
        }
      }
    }  
  }
  &._type02 {
    a {
      &:after {
        display: none;
      }
    }
  }
}



/*====================================================================
LINK CSS
====================================================================*/
.c_link {
  font-family: $noto_sans;
  font-size: 1.6rem;
  text-decoration: underline;
  display: block;
  &._blank {
    display: flex;
    align-items: center;
    &:after {
      @include pseudo-elements('../../img/cmn/icon_blank.svg');
      width: 16px;
      height: 13px;
      margin-left: var(--size-5);
      background-size: contain;
    }
    &._white {
      &:after {
        @include pseudo-elements('../../img/cmn/icon_blank_white.svg');
      }
    }
  }
  &._link_pdf {
    display: inline-flex;
    align-items: center;
    font-size: 1.4rem;
    letter-spacing: 0.04rem;
    &:after {
      @include pseudo-elements('../../img/cmn/icon_pdf.svg');
      width: 18px;
      height: 20px;
      margin-left: var(--size-10);
      background-size: contain;
    }
  }
}

// catering pdf button
.c_catering_pdf_btn {
  a {
    padding: 21px 72px 21px var(--size-20);
    line-height: 1;
    display: inline-block;
    background: #FFF;
    border: 1px solid #858589;
    font-family: $noto_sans;
    border-radius: 8px;
    color: $catering_text_color;
    position: relative;
    transition: .5s;
    font-size: 1.4rem;
    &:after {
      @include pseudo-elements('../../img/cmn/icon_pdg_red.svg');
      width: 30px;
      height: 34px;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      right: 20px;
      transition: .5s;
    }
    &:before {
      @include pseudo-elements('../../img/cmn/right_arrow.svg');
      width: 20px;
      height: 6px;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      right: -3px;
      transition: .5s;
    }
    &:hover {
      transition: .5s;
      background: #858589;
      color: #FFF;
      &:after {
        @include pseudo-elements('../../img/cmn/icon_pdg_white.svg');
      }
      &:before {
        @include pseudo-elements('../../img/cmn/right_arrow_white.svg');
      }
    }
  }
}


/*====================================================================
 BANNER CSS
====================================================================*/
.banner_wrap {
  padding: var(--size-70) 0 var(--size-85);
  .l_inner {
    padding-left: var(--size-40);
    padding-right: var(--size-40);
  }
  .c_banner {
    margin: 0 auto;
    max-width: 712px;
  }
}

/*====================================================================
 FOOTER CSS
====================================================================*/
.footer {
  background: $primary_bg_color;
  &._catering {
    background: $catering_color;
    color: $white_color;
    .l_inner {
      padding: var(--size-65) var(--size-30) var(--size-65);
      @include sp {
        padding-left: var(--size-40);
        padding-right: var(--size-40);
      }
    }
  }
  &._rievenhouse {
    background: $rh_bg_primary;
    color: $white_color;
    .l_inner {
      padding: var(--size-70) var(--size-30) var(--size-70);
      @include sp {
        padding-left: var(--size-40);
        padding-right: var(--size-40);
      }
    }
  }
  &._restaurant {
    background: $rh_bg_primary;
    color: $white_color;
    .l_inner {
      padding: var(--size-70) var(--size-30) var(--size-70);
      @include sp {
        padding-left: var(--size-40);
        padding-right: var(--size-40);
      }
    }
  }
  &._rsc {
    background: #FFCC40;
    color: $rsc_black;
    .l_inner {
      @include sp {
        padding-top: var(--size-120);  
      }
      .c_footer_title {
        color: $rsc_brown;
      }
    }
    .l_footer_service_wrap {
      .heading,
      .c_service_list .c_m_text {
        color: $rsc_brown;
        a {
          color: $rsc_brown;
        }
      }
    }
  }
  &._rievenhouse {
    background: #514A44;
    color: #FFF;
    .l_inner {
      @include sp {
        padding-top: var(--size-120);  
      }
      .c_footer_title {
        color: $rsc_brown;
      }
    }
    .l_footer_service_wrap {
      .heading,
      .c_service_list .c_m_text {
        color: #FFF;
        a {
          color: #FFF;
        }
      }
    }
  }
  &._restaurant {
    background: $primary_bg_color;
    color: #0B0B0B;
    .l_inner {
      @include sp {
        padding-top: var(--size-120);  
      }
      .c_footer_title {
        color: $rsc_brown;
      }
    }
    .l_footer_service_wrap {
      .heading,
      .c_service_list .c_m_text {
        color: #0B0B0B;
        a {
          color: #0B0B0B;
        }
      }
    }
  }
  .l_inner {
    text-align: center;
    padding: var(--size-55) 0 var(--size-45);
    @include sp {
      padding-left: var(--size-40);
      padding-right: var(--size-40);
    }
    .c_footer_title {
      font-family: $noto_sans;
      @include font-weight(bold);
      margin-bottom: var(--size-20);
      @include sp {
        text-align: left;
        margin-bottom: var(--size-30);
      }
    }
  }
}
.c_footer_navi {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px var(--size-40);
  max-width: 870px;
  width: 90%;
  margin: 0 auto;
  margin-bottom: var(--size-40);
  @include sp {
    width: 100%;
    gap: var(--size-30) var(--size-80);
    margin-bottom: var(--size-70);
    //flex-wrap: wrap;
    //justify-content: flex-start;
  }
  li {
    @include sp {
      //margin-right: var(--size-80);
    }
    a {
      font-size: 1.4rem;
      @include sp {
        //padding-bottom: var(--size-30);
      }
    }
  }
  li + li {
    //margin-left: var(--size-40);
    @include sp {
      //margin-left: 0;
    }
  }
}

.l_footer_service_wrap {
  width: 708px;
  margin: 0 auto;
  &._catering, &._rsc, &._rievenhouse,&._restaurant {
    width: 100%;
    .l_service_wrap {
      justify-content: space-between;
      .c_service_list {
        width: 50%;
        @include sp {
          width: 100%;
          .sp-w100 {
            flex-basis: 100%;
          }
          .sp-first {
            @include p-vw(width, 190);
          }
        }
        .c_m_text {
          a {
            color: #FFF;
            display: inline-block;
            padding-bottom: var(--size-15);
          }
        }
      }
      .c_service_list + .c_service_list {
        margin-left: var(--size-30);
        @include sp {
          margin-left: 0;
        }
      }
    }
  }
  &._rsc {
    .l_service_wrap {
      .c_service_list {
        .c_m_text {
          a {
            color: $rsc_black;
          }
        }
        .c_link._blank:after {
          background-image: url(../../img/cmn/icon_blank_white.svg);
        }
      }
    }
    .c_catering_footer_navi li:nth-child(odd)  {
      width: 100%;
    }
    .c_catering_footer_navi._typeCol2 li {
      width: 50%;
    }
  }
  &._rievenhouse {
    .c_service_list {
      .c_catering_footer_navi {
        li:last-child {
          width: auto;
        }
      }
    }
  }
  &._restaurant {
    .l_service_wrap {
      .c_service_list {
        .c_m_text {
          a {
            color: #0B0B0B;
          }
        }
      }  
    }
    .c_service_list {
      .c_catering_footer_navi {
        li:last-child {
          width: auto;
        }
      }
    }
  }
  @include sp {
    width: 100%;
  }
  .heading {
    text-align: left;
    margin-bottom: var(--size-15);
    line-height: 1;
    @include sp {
      margin-bottom: var(--size-30);
    }
  }
  .l_service_wrap {
    display: flex;

    @include sp {
      display: block;
    }
    .c_service_list {
      flex-shrink: 1;
      text-align: left;
      li {
        a {
          font-size: 1.4rem;
          padding-bottom: 7px;
          display: inline-flex;
          @include sp {
            padding-bottom: var(--size-30);
            display: flex;
          }
        }
      }
      .c_m_text {
        a {
          font-size: 1.6rem;
          @include font-weight(bold);
          padding-bottom: var(--size-10);
          @include sp {
            padding-bottom: var(--size-30);
          }
        }
      }
      &._col02 {
        display: flex;
        flex-wrap: wrap;
        width: 310px;
        li {
          width: calc(100% / 2);
          &:nth-child(2n + 3){
            padding-left: var(--size-10);
          }
        }
        .c_m_text {
          width: 100%;
        }
      }
      &._rs {
        @include sp {
          .c_link {
            font-size: get_vw(32);
          }
        }
      }
    }
    .c_service_list + .c_service_list {
      margin-left: var(--size-40);
      @include sp {
        margin-left: 0;
        margin-top: var(--size-30);
      }
    }
    .c_service_list_box {
      margin-left: auto;
      .c_service_list + .c_service_list, {
        margin-left: 0;
        margin-top: var(--size-30);
      }
    }
  }
}

.c_catering_footer_navi {
  display: flex;
  flex-wrap: wrap;
  li:nth-child(odd){
    width: 276px;
    @include sp {
      @include p-vw(width, 374)
    }
  }
  li:nth-child(even){
    //flex: 1;
  }
  @include sp {
    li:nth-child(1){
      order: 1;
    }
    li:nth-child(2){
      order: 3;
    }
    li:nth-child(3){
      order: 2;
    }
    li:nth-child(4){
      order: 4;
    }
    li:nth-child(5){
      order: 5;
    }
  }
}

.c_footer_shop_list {
  display: flex;
  margin-top: var(--size-30);
  &._rievenhouse {
    margin-top: var(--size-60);
  }
  @include sp {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap : var(--size-30) var(--size-30);
  }
  .c_item + .c_item {
    margin-left: var(--size-25);
    @include sp {
      margin-left: 0;
    }
  }
  .c_text {
    font-size: 1.2rem;
    margin-top: var(--size-10);
    @include sp {
      font-size: get_vw(20);
    }
  }
}


.c_copy_right {
  text-align: center;
  background: $black_color;
  font-size: 1.4rem;
  color: $white_color;
  padding: var(--size-15) 0;
  @include sp {
    font-size: get_vw(28);
  }
  &._catering {
    font-family: $noto_sans;
  }
  &._rievenhouse {
    font-family: $noto_sans;
    background: #000;
  }
  &._rsc {
    background: $rsc_brown;
    font-family: $noto_sans;
  }
}

/*====================================================================
 SLIDER CSS
====================================================================*/
.slider {
  font-size: 0;
  position: relative;
}

.ani_box_line {
  position: relative;
  transition: all .3s;
  outline: none;
  &:before, &:after {
    position: absolute;
     top: -1px;
     right: -1px;
     bottom: -1px;
     left: -1px;
     z-index: 2;
     content: '';
     transition: all .3s;
     @include sp {
       display: none;
     }
  }
  &:before {
    border-top: 1px solid $accent_color;
     border-bottom: 1px solid $accent_color;
     transform: scale(0, 1);
  }
  &:after {
    border-right: 1px solid $accent_color;
    border-left: 1px solid $accent_color;
    transform: scale(1, 0);
  }
  &:hover:after, &:hover:before {
    transform: scale(1);
  }
}

.ani_box_line._gray {
  &:before {
    border-top: 1px solid $gray_color;
    border-bottom: 1px solid $gray_color;
  }
  &:after {
    border-right: 1px solid $gray_color;
    border-left: 1px solid $gray_color;
  }
  &:hover:after,
  &:hover:before {
    height: auto !important;
    bottom: -1px !important;
    background: none !important;
  }
}

.ani_box_line._white {
  &:before {
    border-top: 1px solid $white_color;
    border-bottom: 1px solid $white_color;
  }
  &:after {
    border-right: 1px solid $white_color;
    border-left: 1px solid $white_color;
  }
  &:hover:after,
  &:hover:before {
    height: auto !important;
    bottom: -1px !important;
    background: none !important;
  }
}

@keyframes zoomDown {
  0% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.add-animation {
  animation: zoomDown 6s linear 0s normal both;
}

.slick-dots {
  bottom: -25px;
  left: -150px;
  @include sp {
    @include p-vw(right, 40);
    text-align: right;
    left: auto;
  }
}

.slick-dots li button {
  height: 11px;
  width: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  position: relative;
}
.slick-dots li button:before {
  background: #2B2738;
  border-radius: 60px;
  width: 7px;
  height: 7px;
  content: '';
  transition: 0.5s;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  opacity: 0.8;
}

.slick-dots li.slick-active button:before {
  background: #2B2738;
  border-radius: 60px;
  width: 11px;
  height: 11px;
  content: '';
  transition: 0.5s;
  opacity: 1;
}

.slick-dots li {
  margin: 0;
  width: 11px;
  height: 11px;
}
.slick-dots {
  li + li {
    margin-left: var(--size-30);
  }
}

.slick-slide {
  cursor: pointer;
}



/*====================================================================
 PAGE TOP CSS
====================================================================*/
.c_page_top {
  a {
    position: fixed;
    display: block;
    width: var(--size-40);
    height: var(--size-40);
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0B0B0B;
    right: var(--size-20);
    bottom:var(--size-40);
    z-index: 999;
    @include sp {
      width: var(--size-80);
      height: var(--size-80);
    }
    &:before {
      @include pseudo-elements('../../img/cmn/icon_page_top_arrow.svg');
      width: var(--size-20);
      height: var(--size-10);
      @include sp {
        width: var(--size-40);
        height: var(--size-20);
      }
    }
  }
  &._catering {
    a {
      background: $catering_color;
    }
  }
  &._rsc {
    a {
      background: #5D3500;
      &:before {
        @include pseudo-elements('../../img/cmn/icon_page_top_arrow.svg');
      }
    }
  }
  &._rievenhouse {
    a {
      background: #334644;
    }
  }
}

/*====================================================================
 MODAL IMAGE CSS
====================================================================*/
.modaal-image {
  .modaal-close {
    top: -50px;
    right: -55px;
    position: absolute;
    @include sp {
      @include p-vw(top, -200);
      @include p-vw(right, 80);
    }
  }
}

.modaal-close {
  width: auto;
  height: auto;
  &:before, &:after {
    width: 2px;
    height: 37px;
  }
}

.modaal-image .modaal-container {
  max-width: 896px;
}

.modaal-close:focus, .modaal-close:hover {
  background: none;
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after  {
  background: #FFF;
}

@media only screen and (max-width: 1140px) {
  .modaal-image .modaal-inner-wrapper {
    padding-left: calc(140 / 1366 * 100vw);
    padding-right:calc(140 / 1366 * 100vw);
    @include sp {
      padding-left: var(--size-40);
      padding-right:var(--size-40);
    }
  }
}
@include sp {
  .modaal-gallery-item  {
    img {
      @include p-vw(height, 660);
      object-fit: cover;
    }
  }
}

.modaal-video{
  .modaal-container{
    max-width: 900px;
    background-color: transparent;
    box-shadow: none;
  }
  .modaal-close {
    top: -95px;
    right: 40px;
    position: absolute;
    @include sp {
      @include p-vw(top, -200);
      @include p-vw(right, 80);
    }
  }

  .modaal-video-wrap {
    margin: auto;
  }

  .modaal-inner-wrapper {
    padding: 0;
    @include sp {
      padding: 0 10px;
    }
  }

  .modaal-video-container {
    max-width: 900px;
    max-height: 100%;
    height: 100%;
    padding-bottom: 0;
    @include sp {
    }
    iframe,
    object,
    embed {
      position: static;
      aspect-ratio: 16/9;
    }
  }
}


/*====================================================================
 Accordion-title CSS
====================================================================*/
@include sp {
  .accordion-title {
    position: relative;
    cursor: pointer;
    &:before, &:after {
      position: absolute;
      content:'';
      width: var(--size-50);
      height: 1px;
      background-color: $accent_color;
      transform: translate(-50%, -50%);
      top: 50%;
      right: 0;
      transition: .3s;
    }
     &:after {
       transform: translate(-50%, -50%) rotate(90deg);
     }
     &.close {
       &:before{
         transform: rotate(45deg);
         right: var(--size-20);
       }
       &:after {
        transform: rotate(-45deg);
        right: var(--size-20);
      }
     }
  }
}

