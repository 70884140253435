@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@200;300;400;500;600;700;900&display=swap");
/* rsc */
/* constant */
@import url("https://fonts.googleapis.com/css2?family=M+PLUS+1p&family=Noto+Serif+JP:wght@200;300;400;500;600;700;900&display=swap");
:root {
  --size-5: calc(5 / 750 * 100vw);
  --size-10: calc(10 / 750 * 100vw);
  --size-15: calc(15 / 750 * 100vw);
  --size-20: calc(20 / 750 * 100vw);
  --size-25: calc(25 / 750 * 100vw);
  --size-30: calc(30 / 750 * 100vw);
  --size-35: calc(35 / 750 * 100vw);
  --size-40: calc(40 / 750 * 100vw);
  --size-45: calc(45 / 750 * 100vw);
  --size-50: calc(50 / 750 * 100vw);
  --size-55: calc(55 / 750 * 100vw);
  --size-60: calc(60 / 750 * 100vw);
  --size-65: calc(65 / 750 * 100vw);
  --size-70: calc(70 / 750 * 100vw);
  --size-75: calc(75 / 750 * 100vw);
  --size-80: calc(80 / 750 * 100vw);
  --size-85: calc(85 / 750 * 100vw);
  --size-90: calc(90 / 750 * 100vw);
  --size-95: calc(95 / 750 * 100vw);
  --size-100: calc(100 / 750 * 100vw);
  --size-105: calc(105 / 750 * 100vw);
  --size-110: calc(110 / 750 * 100vw);
  --size-115: calc(115 / 750 * 100vw);
  --size-120: calc(120 / 750 * 100vw);
  --size-125: calc(125 / 750 * 100vw);
  --size-130: calc(130 / 750 * 100vw);
  --size-135: calc(135 / 750 * 100vw);
  --size-140: calc(140 / 750 * 100vw);
  --size-145: calc(145 / 750 * 100vw);
  --size-150: calc(150 / 750 * 100vw);
  --size-155: calc(155 / 750 * 100vw);
  --size-160: calc(160 / 750 * 100vw);
  --size-165: calc(165 / 750 * 100vw);
  --size-170: calc(170 / 750 * 100vw);
  --size-175: calc(175 / 750 * 100vw);
  --size-180: calc(180 / 750 * 100vw);
  --size-185: calc(185 / 750 * 100vw);
  --size-190: calc(190 / 750 * 100vw);
  --size-195: calc(195 / 750 * 100vw);
  --size-200: calc(200 / 750 * 100vw);
  --size-205: calc(205 / 750 * 100vw);
  --size-210: calc(210 / 750 * 100vw);
  --size-215: calc(215 / 750 * 100vw);
  --size-220: calc(220 / 750 * 100vw);
  --size-225: calc(225 / 750 * 100vw);
  --size-230: calc(230 / 750 * 100vw);
  --size-235: calc(235 / 750 * 100vw);
  --size-240: calc(240 / 750 * 100vw);
  --size-245: calc(245 / 750 * 100vw);
  --size-250: calc(250 / 750 * 100vw);
  --size-255: calc(255 / 750 * 100vw);
  --size-260: calc(260 / 750 * 100vw);
  --size-265: calc(265 / 750 * 100vw);
  --size-270: calc(270 / 750 * 100vw);
  --size-275: calc(275 / 750 * 100vw);
  --size-280: calc(280 / 750 * 100vw);
  --size-285: calc(285 / 750 * 100vw);
  --size-290: calc(290 / 750 * 100vw);
  --size-295: calc(295 / 750 * 100vw);
  --size-300: calc(300 / 750 * 100vw);
  --size-305: calc(305 / 750 * 100vw);
  --size-310: calc(310 / 750 * 100vw);
  --size-315: calc(315 / 750 * 100vw);
  --size-320: calc(320 / 750 * 100vw);
  --size-325: calc(325 / 750 * 100vw);
  --size-330: calc(330 / 750 * 100vw);
  --size-335: calc(335 / 750 * 100vw);
  --size-340: calc(340 / 750 * 100vw);
  --size-345: calc(345 / 750 * 100vw);
  --size-350: calc(350 / 750 * 100vw);
  --size-355: calc(355 / 750 * 100vw);
  --size-360: calc(360 / 750 * 100vw);
  --size-365: calc(365 / 750 * 100vw);
  --size-370: calc(370 / 750 * 100vw);
  --size-375: calc(375 / 750 * 100vw);
  --size-380: calc(380 / 750 * 100vw);
  --size-385: calc(385 / 750 * 100vw);
  --size-390: calc(390 / 750 * 100vw);
  --size-395: calc(395 / 750 * 100vw);
  --size-400: calc(400 / 750 * 100vw);
  --size-405: calc(405 / 750 * 100vw);
  --size-410: calc(410 / 750 * 100vw);
  --size-415: calc(415 / 750 * 100vw);
  --size-420: calc(420 / 750 * 100vw);
  --size-425: calc(425 / 750 * 100vw);
  --size-430: calc(430 / 750 * 100vw);
  --size-435: calc(435 / 750 * 100vw);
  --size-440: calc(440 / 750 * 100vw);
  --size-445: calc(445 / 750 * 100vw);
  --size-450: calc(450 / 750 * 100vw);
  --size-455: calc(455 / 750 * 100vw);
  --size-460: calc(460 / 750 * 100vw);
  --size-465: calc(465 / 750 * 100vw);
  --size-470: calc(470 / 750 * 100vw);
  --size-475: calc(475 / 750 * 100vw);
  --size-480: calc(480 / 750 * 100vw);
  --size-485: calc(485 / 750 * 100vw);
  --size-490: calc(490 / 750 * 100vw);
  --size-495: calc(495 / 750 * 100vw);
  --size-500: calc(500 / 750 * 100vw);
}

@media screen and (min-width: 1006px) {
  :root {
    --size-5: 5px;
    --size-10: 10px;
    --size-15: 15px;
    --size-20: 20px;
    --size-25: 25px;
    --size-30: 30px;
    --size-35: 35px;
    --size-40: 40px;
    --size-45: 45px;
    --size-50: 50px;
    --size-55: 55px;
    --size-60: 60px;
    --size-65: 65px;
    --size-70: 70px;
    --size-75: 75px;
    --size-80: 80px;
    --size-85: 85px;
    --size-90: 90px;
    --size-95: 95px;
    --size-100: 100px;
    --size-105: 105px;
    --size-110: 110px;
    --size-115: 115px;
    --size-120: 120px;
    --size-125: 125px;
    --size-130: 130px;
    --size-135: 135px;
    --size-140: 140px;
    --size-145: 145px;
    --size-150: 150px;
    --size-155: 155px;
    --size-160: 160px;
    --size-165: 165px;
    --size-170: 170px;
    --size-175: 175px;
    --size-180: 180px;
    --size-185: 185px;
    --size-190: 190px;
    --size-195: 195px;
    --size-200: 200px;
    --size-205: 205px;
    --size-210: 210px;
    --size-215: 215px;
    --size-220: 220px;
    --size-225: 225px;
    --size-230: 230px;
    --size-235: 235px;
    --size-240: 240px;
    --size-245: 245px;
    --size-250: 250px;
    --size-255: 255px;
    --size-260: 260px;
    --size-265: 265px;
    --size-270: 270px;
    --size-275: 275px;
    --size-280: 280px;
    --size-285: 285px;
    --size-290: 290px;
    --size-295: 295px;
    --size-300: 300px;
    --size-305: 305px;
    --size-310: 310px;
    --size-315: 315px;
    --size-320: 320px;
    --size-325: 325px;
    --size-330: 330px;
    --size-335: 335px;
    --size-340: 340px;
    --size-345: 345px;
    --size-350: 350px;
    --size-355: 355px;
    --size-360: 360px;
    --size-365: 365px;
    --size-370: 370px;
    --size-375: 375px;
    --size-380: 380px;
    --size-385: 385px;
    --size-390: 390px;
    --size-395: 395px;
    --size-400: 400px;
    --size-405: 405px;
    --size-410: 410px;
    --size-415: 415px;
    --size-420: 420px;
    --size-425: 425px;
    --size-430: 430px;
    --size-435: 435px;
    --size-440: 440px;
    --size-445: 445px;
    --size-450: 450px;
    --size-455: 455px;
    --size-460: 460px;
    --size-465: 465px;
    --size-470: 470px;
    --size-475: 475px;
    --size-480: 480px;
    --size-485: 485px;
    --size-490: 490px;
    --size-495: 495px;
    --size-500: 500px;
  }
}
/* reset */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

address {
  font-style: normal;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  vertical-align: bottom;
  max-width: 100%;
  height: auto;
}

/* border-box */
*, *:before, *:after {
  box-sizing: border-box;
}

/* common */
html, body {
  height: 100%;
}

html {
  font-size: 62.5%;
}

body {
  color: #0B0B0B;
  font-weight: normal;
  line-height: 1.5;
  font-size: 1.6rem;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  font-family: "Noto Serif JP", serif;
}

h1, h2, h3, h4, h5, h6,
table th {
  font-weight: 300;
}

a,
._hover {
  color: inherit;
  transition: opacity 0.3s;
  text-decoration: none;
  cursor: pointer;
}

.link_disabled {
  pointer-events: none;
}

a:hover,
._hover:hover {
  opacity: 0.6;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
}

input[type=submit] {
  -webkit-appearance: none;
  border-radius: 0;
}

._pcOnly {
  display: block;
}

._spOnly {
  display: none;
}

@media screen and (max-width: 768px) {
  ._pcOnly {
    display: none;
  }
  ._spOnly {
    display: block;
  }
}
/*====================================================================
 COMMON BACKGROUND CSS
====================================================================*/
section._cmn-beige {
  background: #F8F5EC;
}

section._rievenhouse {
  background: #F3F7ED;
}

/*====================================================================
 TEXT COLOR CSS
====================================================================*/
.text_accent_color {
  color: #A58457;
}

/*====================================================================
 LAYOUTS CSS
====================================================================*/
.l_inner {
  max-width: 1140px;
  margin: 0 auto;
  padding-left: var(--size-30);
  padding-right: var(--size-30);
  position: relative;
}

@media screen and (max-width: 768px) {
  .l_inner {
    padding-left: var(--size-40);
    padding-right: var(--size-40);
  }
}
.l_inner._fullwidth {
  max-width: initial;
  padding-left: 0;
  padding-right: 0;
}

.l_inner._flex {
  display: flex;
  position: relative;
}

@media screen and (max-width: 768px) {
  .l_inner._flex {
    display: block;
  }
}
/*====================================================================
 TITLE COMMON CSS
====================================================================*/
.c_section_title {
  text-align: center;
  display: inline-block;
}

.c_section_title .c_title {
  font-size: 1.6rem;
  font-family: "Noto Sans JP", sans-serif;
  margin-top: var(--size-10);
}

@media screen and (max-width: 768px) {
  .c_section_title .c_title {
    font-size: 3.2vw;
  }
}
.c_section_title .c_title._en {
  font-family: "Lora", serif;
  font-size: 5.8rem;
  position: relative;
  line-height: 1;
  color: #DDDDDD;
  margin-top: 0;
  padding-bottom: var(--size-10);
}

@media screen and (max-width: 768px) {
  .c_section_title .c_title._en {
    font-size: 11.2vw;
  }
}
.c_section_title .c_title._en:before {
  content: "";
  display: block;
  background: #7162A0;
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.c_section_title .c_title._en._catering:before {
  background: #7162A0;
}

.c_section_title .c_title._en._conveni:before {
  background: #6CA06E;
}

.c_section_title .c_title._en._restaurent:before {
  background: #DDA54B;
}

.c_section_title._catering .c_title {
  color: #A58457;
  font-size: 1.2rem;
}

.c_section_title._catering .c_title._en {
  color: #151431;
  font-size: 4.6rem;
  padding-bottom: 0;
}

.c_section_title._catering .c_title._en:before {
  display: none;
}

.c_sec_title {
  text-align: center;
  display: inline-block;
}

.c_sec_title .c_title_en {
  font-size: 4.6rem;
  font-family: "Lora", serif;
  color: #2B2738;
  line-height: 1;
  letter-spacing: 0.2rem;
}

@media screen and (max-width: 768px) {
  .c_sec_title .c_title_en {
    font-size: 9.6vw;
  }
}
.c_sec_title .c_title_ja {
  font-size: 1.2rem;
  font-family: "Noto Sans JP", sans-serif;
  line-height: 1;
  margin-top: var(--size-10);
  color: #A58457;
  letter-spacing: 0.1rem;
}

@media screen and (max-width: 768px) {
  .c_sec_title .c_title_ja {
    font-size: 3.2vw;
  }
}
.c_sec_title._left {
  text-align: left;
}

.c_sec_title._brown .c_title_en, .c_sec_title._brown .c_title_ja {
  color: #A58457;
}

.c_sec_title._gray .c_title_en, .c_sec_title._gray .c_title_ja {
  color: #858589;
}

.c_sec_title._white .c_title_en, .c_sec_title._white .c_title_ja {
  color: #FFFFFF;
}

.c_sec_title._type02 .c_title_en {
  color: #FFFFFF;
}

.c_sec_title._rsc._white .c_title_en {
  color: #FFFFFF;
}

.c_sec_title._rsc._white .c_title_ja {
  color: #5D3500;
}

.c_sec_title._rsc._white._type02 .c_title_ja {
  color: #FFFFFF;
}

.c_sec_title._rsc._orange .c_title_en {
  color: #F5A602;
}

.c_sec_title._rsc._orange .c_title_ja {
  color: #5D3500;
}

.c_sec_title._rsc .c_title_en {
  font-family: "Montserrat", sans-serif;
  color: #0B0B0B;
  font-size: 5.2rem;
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .c_sec_title._rsc .c_title_en {
    font-size: 10.6666666667vw;
  }
}
.c_sec_title._rsc .c_title_ja {
  color: #0B0B0B;
  font-size: 1.6rem;
  font-weight: 600;
  margin-top: var(--size-5);
}

@media screen and (max-width: 768px) {
  .c_sec_title._rsc .c_title_ja {
    font-size: 3.7333333333vw;
  }
}
.heading {
  font-family: "Noto Serif JP", serif;
  text-align: center;
}

.heading._type01 {
  font-size: 2.8rem;
  font-weight: 300;
}

@media screen and (max-width: 768px) {
  .heading._type01 {
    font-size: 6.4vw;
  }
}
.heading._type02 {
  font-size: 2.4rem;
  font-weight: 300;
}

@media screen and (max-width: 768px) {
  .heading._type02 {
    font-size: 5.8666666667vw;
  }
}
.heading._type03 {
  font-size: 2.2rem;
  font-weight: 600;
}

.heading._type04 {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 1.8rem;
  font-weight: 600;
}

.c_text {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 1.6rem;
}

@media screen and (max-width: 768px) {
  .c_text {
    font-size: 4.2666666667vw;
  }
}
.c_text a {
  text-decoration: underline;
}

.c_text._brown {
  color: #A58457;
}

.c_note {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 1rem;
}

/*====================================================================
 UNDER LAYER COMMON CSS
====================================================================*/
.c_ul_heading {
  height: 200px;
  background-position: center;
}

@media screen and (max-width: 768px) {
  .c_ul_heading {
    height: auto;
  }
  .c_ul_heading .l_inner {
    padding-left: var(--size-40);
    padding-right: var(--size-40);
    height: var(--size-270);
    position: relative;
  }
}
.c_ul_heading._about {
  content: "";
  display: block;
  background-image: url("../../img/about/about_top.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-size: cover;
}

.c_ul_heading._privacy {
  content: "";
  display: block;
  background-image: url("../../img/privacy/privacy_top.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  .c_ul_heading._privacy {
    content: "";
    display: block;
    background-image: url("../../img/privacy/privacy_top_sp.png");
    background-size: contain;
    background-repeat: no-repeat;
  }
}
.c_ul_heading._news {
  content: "";
  display: block;
  background-image: url("../../img/news/news_top.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  .c_ul_heading._news {
    content: "";
    display: block;
    background-image: url("../../img/news/news_top_sp.png");
    background-size: contain;
    background-repeat: no-repeat;
  }
}
.c_ul_heading._hall {
  content: "";
  display: block;
  background-image: url("../../img/hall/hall_top.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-size: cover;
}

.c_ul_heading._option {
  content: "";
  display: block;
  background-image: url("../../img/option/option_top.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-size: cover;
}

.c_ul_heading._example {
  content: "";
  display: block;
  background-image: url("../../img/example/example_top.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-size: cover;
}

.c_ul_heading._guide {
  content: "";
  display: block;
  background-image: url("../../img/guide/guide_top.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  .c_ul_heading._guide {
    content: "";
    display: block;
    background-image: url("../../img/guide/guide_top_sp.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-size: 101%;
  }
}
.c_ul_heading._planlist {
  content: "";
  display: block;
  background-image: url("../../img/planlist/planlist_top.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  .c_ul_heading._planlist {
    content: "";
    display: block;
    background-image: url("../../img/planlist/planlist_top_sp.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-size: 101%;
  }
}
.c_ul_heading._lunchbox {
  content: "";
  display: block;
  background-image: url("../../img/lunchbox/lunchbox_top.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-size: cover;
}

.c_ul_heading._drink {
  content: "";
  display: block;
  background-image: url("../../img/drink/drink_top.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-size: cover;
}

.c_ul_heading._contact {
  content: "";
  display: block;
  background-image: url("../../img/contact/contact_top.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  .c_ul_heading._contact {
    content: "";
    display: block;
    background-image: url("../../img/contact/contact_top_sp.png");
    background-size: contain;
    background-repeat: no-repeat;
  }
}
.c_ul_heading._rievenhouse-topics {
  content: "";
  display: block;
  background-image: url("../../img/rievenhouse-topics/rievenhouse_topics_top.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-size: cover;
}

.c_ul_heading._notfound {
  content: "";
  display: block;
  background-image: url("../../img/notfound/notfound_top.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  .c_ul_heading._notfound {
    content: "";
    display: block;
    background-image: url("../../img/notfound/notfound_top_sp.png");
    background-size: contain;
    background-repeat: no-repeat;
  }
}
.c_ul_heading._maintenance {
  content: "";
  display: block;
  background-image: url("../../img/maintenance/maintenance_top.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  .c_ul_heading._maintenance {
    content: "";
    display: block;
    background-image: url("../../img/maintenance/maintenance_top_sp.png");
    background-size: contain;
    background-repeat: no-repeat;
  }
}
.c_ul_title_wrap {
  text-align: center;
  padding-top: var(--size-45);
}

@media screen and (max-width: 768px) {
  .c_ul_title_wrap {
    padding-top: initial;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    position: absolute;
    width: 90%;
  }
}
.c_ul_title_wrap._type02 {
  text-align: left;
}

.c_ul_title_wrap._type02 .c_ul_title._en {
  opacity: 0.7;
  font-size: 4.6rem;
}

@media screen and (max-width: 768px) {
  .c_ul_title_wrap._type02 .c_ul_title._en {
    font-size: 8.5333333333vw;
    letter-spacing: 0;
  }
}
.c_ul_title_wrap._type02 .c_ul_title._ja h1 {
  font-size: 2rem;
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .c_ul_title_wrap._type02 .c_ul_title._ja h1 {
    font-size: 3.7333333333vw;
  }
}
.c_ul_title_wrap .c_ul_title._en {
  color: #FFFFFF;
  opacity: 0.2;
  font-family: "Lora", serif;
  font-size: 5.6rem;
  line-height: 1;
  padding-bottom: var(--size-10);
  letter-spacing: 0.15rem;
}

.c_ul_title_wrap .c_ul_title._en._type02 {
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .c_ul_title_wrap .c_ul_title._en {
    font-size: 8.5333333333vw;
  }
}
.c_ul_title_wrap .c_ul_title._ja {
  font-family: "Noto Sans JP", sans-serif;
}

.c_ul_title_wrap .c_ul_title._ja h1 {
  font-size: 1.6rem;
  color: #FFFFFF;
  line-height: 1;
  font-weight: 300;
}

@media screen and (max-width: 768px) {
  .c_ul_title_wrap .c_ul_title._ja h1 {
    font-size: 3.7333333333vw;
  }
}
.c_breadcrumb {
  color: #FFFFFF;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 1rem;
  display: flex;
  padding-top: var(--size-15);
}

@media screen and (max-width: 768px) {
  .c_breadcrumb {
    display: none;
  }
}
.c_breadcrumb .item {
  display: flex;
  align-items: center;
}

.c_breadcrumb .item a {
  text-decoration: underline;
}

.c_breadcrumb .item:after {
  content: "/";
  display: block;
  margin-left: var(--size-10);
}

.c_breadcrumb .item._current:after {
  display: none;
}

.c_breadcrumb .item + .item {
  margin-left: var(--size-10);
}

.c_ul_sec_title {
  text-align: center;
}

.c_ul_sec_title .title {
  font-size: 2.8rem;
  text-align: center;
  line-height: 1;
  position: relative;
  display: inline-block;
  font-weight: 300;
  letter-spacing: 0.4rem;
  font-family: "Noto Serif JP", serif;
}

@media screen and (max-width: 768px) {
  .c_ul_sec_title .title {
    font-size: 6.4vw;
    letter-spacing: -0.01rem;
    line-height: 1.4;
  }
}
.c_ul_sec_title .title:before {
  content: "";
  display: block;
  background-color: #A58457;
  width: 28px;
  height: 2px;
  margin: 0 auto var(--size-20);
}

.c_ul_sec_title .title._notosans {
  font-family: "Noto Sans JP", sans-serif;
}

.c_ul_sec_title._none .title:before {
  display: none;
}

.c_ul_under_title {
  border-bottom: 1px solid #A58457;
}

.c_ul_under_title .title {
  font-family: "Noto Serif JP", serif;
  font-size: 2.2rem;
  line-height: 1;
  padding-bottom: var(--size-10);
}

@media screen and (max-width: 768px) {
  .c_ul_under_title .title {
    font-size: 5.8666666667vw;
  }
}
/*====================================================================
 BUTTON CSS
====================================================================*/
.c_btn {
  display: inline-block;
}

@media screen and (max-width: 768px) {
  .c_btn .c_text {
    font-size: 3.7333333333vw;
  }
}
.c_btn a, .c_btn input[type=submit] {
  border-width: 0;
  background: #A58457;
  display: block;
  font-family: "Noto Sans JP", sans-serif;
  color: #FFFFFF;
  font-size: 1.4rem;
  padding: var(--size-20) 0;
  width: 252px;
  height: 64px;
  text-align: center;
  transition: 0.5s;
  box-sizing: border-box;
  letter-spacing: 0.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .c_btn a, .c_btn input[type=submit] {
    height: 17.0666666667vw;
    width: 59.2vw;
    font-size: 3.7333333333vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.c_btn a:hover, .c_btn input[type=submit]:hover {
  background: #FFFFFF;
  border: 1px solid #A58457;
  color: #A58457;
  opacity: 1;
  transition: 0.5s;
}

.c_btn a:active, .c_btn input[type=submit]:active {
  background: #0B0B0B;
  color: #FFFFFF;
  opacity: 1;
  transition: 0.5s;
}

.c_btn a:disabled, .c_btn a:disabled:hover, .c_btn input[type=submit]:disabled, .c_btn input[type=submit]:disabled:hover {
  background: #d8c9b4;
  border: none;
  color: #FFF;
}

.c_btn._back a {
  background: #F2F2F2;
  color: #0B0B0B;
}

.c_btn._back a:hover {
  background: none;
  color: #0B0B0B;
  border: 1px solid #0B0B0B;
}

.c_btn._bold a {
  font-weight: 600;
}

.c_btn._green a {
  background: #334644;
}

.c_btn._green a:hover {
  border: 1px solid #334644;
  background: #FFF;
  color: #334644;
}

.c_btn._blank {
  position: relative;
}

.c_btn._blank a:after {
  content: "";
  display: block;
  background-image: url("../../img/cmn/icon_blank_white.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 16px;
  height: 13px;
  background-size: contain;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  right: 2px;
}

.c_btn._blank a:hover:after {
  content: "";
  display: block;
  background-image: url("../../img/cmn/icon_blank_brown.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.c_btn_more {
  text-align: right;
}

.c_btn_more a {
  font-size: 1.4rem;
  display: inline-block;
  padding: var(--size-15) var(--size-35) var(--size-15) var(--size-35);
  position: relative;
  line-height: 1;
  letter-spacing: 0.3rem;
  font-family: "Noto Serif JP", serif;
}

@media screen and (max-width: 768px) {
  .c_btn_more a {
    font-size: 4.2666666667vw;
    padding: var(--size-30) var(--size-100) var(--size-30) var(--size-65);
  }
}
.c_btn_more a:before, .c_btn_more a:after {
  content: "";
  display: block;
  height: 100%;
  width: 2px;
  background: #A58457;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
}

.c_btn_more a:after {
  height: 0;
  transition: 0.5s;
  opacity: 1;
  top: initial;
  bottom: 0;
}

.c_btn_more a:hover:after {
  height: 100%;
  transition: 0.5s;
  opacity: 1;
}

.c_btn_more a .arrow:after {
  content: "";
  display: block;
  background-image: url("../../img/cmn/right_arrow.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 20px;
  height: 6px;
  position: absolute;
  transform: translate(-50%, -50%);
  right: -10px;
  top: 50%;
}

@media screen and (max-width: 768px) {
  .c_btn_more a .arrow:after {
    height: 2.1333333333vw;
    width: 7.4666666667vw;
    background-size: contain;
    right: -4vw;
  }
}
.c_btn_more._gray a:before, .c_btn_more._gray a:after {
  background: #151431;
}

.c_btn_more._white a {
  color: #FFF;
}

.c_btn_more._white a .arrow:after {
  content: "";
  display: block;
  background-image: url("../../img/cmn/right_arrow_white.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.c_rsc_btn {
  display: inline-block;
}

.c_rsc_btn a {
  background: #5D3500;
  font-size: 1.6rem;
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 600;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--size-15) var(--size-25) var(--size-15) var(--size-40);
  border-radius: var(--size-30);
  line-height: 1;
  position: relative;
}

@media screen and (max-width: 768px) {
  .c_rsc_btn a {
    font-size: 4.2666666667vw;
    height: 14.9333333333vw;
    width: 67.2vw;
    border-radius: 7.4666666667vw;
    text-indent: -8vw;
  }
}
.c_rsc_btn a:after {
  content: "";
  display: block;
  background-image: url("../../img/cmn/icon_rsc_btn_arrow_white.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: var(--size-30);
  height: var(--size-30);
  margin-left: var(--size-15);
}

@media screen and (max-width: 768px) {
  .c_rsc_btn a:after {
    width: var(--size-60);
    height: var(--size-60);
    transform: translate(-50%, -50%);
    top: 50%;
    position: absolute;
    right: var(--size-10);
  }
}
@media screen and (max-width: 768px) {
  .c_rsc_btn._white {
    width: 100%;
  }
}
.c_rsc_btn._white .c_sub_text {
  color: #FFFFFF;
  font-size: 1.6rem;
  margin-bottom: var(--size-10);
  font-family: "Noto Sans JP", sans-serif;
}

@media screen and (max-width: 768px) {
  .c_rsc_btn._white .c_sub_text {
    font-size: 4.2666666667vw;
  }
}
.c_rsc_btn._white a {
  flex-wrap: wrap;
  width: 436px;
  background: #FFFFFF;
  color: #F5A602;
  border-radius: 6px;
  font-size: 2.2rem;
  color: #5D3500;
  height: var(--size-100);
  position: relative;
  padding: var(--size-15) 0;
  line-height: 1.4;
  text-indent: initial;
}

.c_rsc_btn._white a .c_tel_wrap .num {
  line-height: 1;
  letter-spacing: 0.1rem;
}

@media screen and (max-width: 768px) {
  .c_rsc_btn._white a {
    width: 100%;
    font-size: 4.2666666667vw;
    height: var(--size-160);
  }
}
.c_rsc_btn._white a:after {
  content: "";
  display: block;
  background-image: url("../../img/cmn/icon_rsc_btn_arrow_orange.svg");
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  right: 5px;
}

.c_rsc_btn._white a .num {
  color: #F5A602;
  width: 100%;
  font-size: 3.6rem;
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .c_rsc_btn._white a .num {
    font-size: 8vw;
  }
}
.c_rsc_btn._white a .note {
  display: block;
  font-size: 1.4rem;
}

@media screen and (max-width: 768px) {
  .c_rsc_btn._white a .note {
    font-size: 2.9333333333vw;
  }
}
.c_rsc_btn._type02 a:after {
  display: none;
}

/*====================================================================
LINK CSS
====================================================================*/
.c_link {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 1.6rem;
  text-decoration: underline;
  display: block;
}

.c_link._blank {
  display: flex;
  align-items: center;
}

.c_link._blank:after {
  content: "";
  display: block;
  background-image: url("../../img/cmn/icon_blank.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 16px;
  height: 13px;
  margin-left: var(--size-5);
  background-size: contain;
}

.c_link._blank._white:after {
  content: "";
  display: block;
  background-image: url("../../img/cmn/icon_blank_white.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.c_link._link_pdf {
  display: inline-flex;
  align-items: center;
  font-size: 1.4rem;
  letter-spacing: 0.04rem;
}

.c_link._link_pdf:after {
  content: "";
  display: block;
  background-image: url("../../img/cmn/icon_pdf.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 18px;
  height: 20px;
  margin-left: var(--size-10);
  background-size: contain;
}

.c_catering_pdf_btn a {
  padding: 21px 72px 21px var(--size-20);
  line-height: 1;
  display: inline-block;
  background: #FFF;
  border: 1px solid #858589;
  font-family: "Noto Sans JP", sans-serif;
  border-radius: 8px;
  color: #3B4043;
  position: relative;
  transition: 0.5s;
  font-size: 1.4rem;
}

.c_catering_pdf_btn a:after {
  content: "";
  display: block;
  background-image: url("../../img/cmn/icon_pdg_red.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 30px;
  height: 34px;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  right: 20px;
  transition: 0.5s;
}

.c_catering_pdf_btn a:before {
  content: "";
  display: block;
  background-image: url("../../img/cmn/right_arrow.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 20px;
  height: 6px;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  right: -3px;
  transition: 0.5s;
}

.c_catering_pdf_btn a:hover {
  transition: 0.5s;
  background: #858589;
  color: #FFF;
}

.c_catering_pdf_btn a:hover:after {
  content: "";
  display: block;
  background-image: url("../../img/cmn/icon_pdg_white.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.c_catering_pdf_btn a:hover:before {
  content: "";
  display: block;
  background-image: url("../../img/cmn/right_arrow_white.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

/*====================================================================
 BANNER CSS
====================================================================*/
.banner_wrap {
  padding: var(--size-70) 0 var(--size-85);
}

.banner_wrap .l_inner {
  padding-left: var(--size-40);
  padding-right: var(--size-40);
}

.banner_wrap .c_banner {
  margin: 0 auto;
  max-width: 712px;
}

/*====================================================================
 FOOTER CSS
====================================================================*/
.footer {
  background: #F8F5EC;
}

.footer._catering {
  background: #2B2738;
  color: #FFFFFF;
}

.footer._catering .l_inner {
  padding: var(--size-65) var(--size-30) var(--size-65);
}

@media screen and (max-width: 768px) {
  .footer._catering .l_inner {
    padding-left: var(--size-40);
    padding-right: var(--size-40);
  }
}
.footer._rievenhouse {
  background: #3E2104;
  color: #FFFFFF;
}

.footer._rievenhouse .l_inner {
  padding: var(--size-70) var(--size-30) var(--size-70);
}

@media screen and (max-width: 768px) {
  .footer._rievenhouse .l_inner {
    padding-left: var(--size-40);
    padding-right: var(--size-40);
  }
}
.footer._restaurant {
  background: #3E2104;
  color: #FFFFFF;
}

.footer._restaurant .l_inner {
  padding: var(--size-70) var(--size-30) var(--size-70);
}

@media screen and (max-width: 768px) {
  .footer._restaurant .l_inner {
    padding-left: var(--size-40);
    padding-right: var(--size-40);
  }
}
.footer._rsc {
  background: #FFCC40;
  color: #2C2C2C;
}

@media screen and (max-width: 768px) {
  .footer._rsc .l_inner {
    padding-top: var(--size-120);
  }
}
.footer._rsc .l_inner .c_footer_title {
  color: #5D3500;
}

.footer._rsc .l_footer_service_wrap .heading,
.footer._rsc .l_footer_service_wrap .c_service_list .c_m_text {
  color: #5D3500;
}

.footer._rsc .l_footer_service_wrap .heading a,
.footer._rsc .l_footer_service_wrap .c_service_list .c_m_text a {
  color: #5D3500;
}

.footer._rievenhouse {
  background: #514A44;
  color: #FFF;
}

@media screen and (max-width: 768px) {
  .footer._rievenhouse .l_inner {
    padding-top: var(--size-120);
  }
}
.footer._rievenhouse .l_inner .c_footer_title {
  color: #5D3500;
}

.footer._rievenhouse .l_footer_service_wrap .heading,
.footer._rievenhouse .l_footer_service_wrap .c_service_list .c_m_text {
  color: #FFF;
}

.footer._rievenhouse .l_footer_service_wrap .heading a,
.footer._rievenhouse .l_footer_service_wrap .c_service_list .c_m_text a {
  color: #FFF;
}

.footer._restaurant {
  background: #F8F5EC;
  color: #0B0B0B;
}

@media screen and (max-width: 768px) {
  .footer._restaurant .l_inner {
    padding-top: var(--size-120);
  }
}
.footer._restaurant .l_inner .c_footer_title {
  color: #5D3500;
}

.footer._restaurant .l_footer_service_wrap .heading,
.footer._restaurant .l_footer_service_wrap .c_service_list .c_m_text {
  color: #0B0B0B;
}

.footer._restaurant .l_footer_service_wrap .heading a,
.footer._restaurant .l_footer_service_wrap .c_service_list .c_m_text a {
  color: #0B0B0B;
}

.footer .l_inner {
  text-align: center;
  padding: var(--size-55) 0 var(--size-45);
}

@media screen and (max-width: 768px) {
  .footer .l_inner {
    padding-left: var(--size-40);
    padding-right: var(--size-40);
  }
}
.footer .l_inner .c_footer_title {
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 600;
  margin-bottom: var(--size-20);
}

@media screen and (max-width: 768px) {
  .footer .l_inner .c_footer_title {
    text-align: left;
    margin-bottom: var(--size-30);
  }
}
.c_footer_navi {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px var(--size-40);
  max-width: 870px;
  width: 90%;
  margin: 0 auto;
  margin-bottom: var(--size-40);
}

@media screen and (max-width: 768px) {
  .c_footer_navi {
    width: 100%;
    gap: var(--size-30) var(--size-80);
    margin-bottom: var(--size-70);
  }
}
.c_footer_navi li a {
  font-size: 1.4rem;
}

.l_footer_service_wrap {
  width: 708px;
  margin: 0 auto;
}

.l_footer_service_wrap._catering, .l_footer_service_wrap._rsc, .l_footer_service_wrap._rievenhouse, .l_footer_service_wrap._restaurant {
  width: 100%;
}

.l_footer_service_wrap._catering .l_service_wrap, .l_footer_service_wrap._rsc .l_service_wrap, .l_footer_service_wrap._rievenhouse .l_service_wrap, .l_footer_service_wrap._restaurant .l_service_wrap {
  justify-content: space-between;
}

.l_footer_service_wrap._catering .l_service_wrap .c_service_list, .l_footer_service_wrap._rsc .l_service_wrap .c_service_list, .l_footer_service_wrap._rievenhouse .l_service_wrap .c_service_list, .l_footer_service_wrap._restaurant .l_service_wrap .c_service_list {
  width: 50%;
}

@media screen and (max-width: 768px) {
  .l_footer_service_wrap._catering .l_service_wrap .c_service_list, .l_footer_service_wrap._rsc .l_service_wrap .c_service_list, .l_footer_service_wrap._rievenhouse .l_service_wrap .c_service_list, .l_footer_service_wrap._restaurant .l_service_wrap .c_service_list {
    width: 100%;
  }
  .l_footer_service_wrap._catering .l_service_wrap .c_service_list .sp-w100, .l_footer_service_wrap._rsc .l_service_wrap .c_service_list .sp-w100, .l_footer_service_wrap._rievenhouse .l_service_wrap .c_service_list .sp-w100, .l_footer_service_wrap._restaurant .l_service_wrap .c_service_list .sp-w100 {
    flex-basis: 100%;
  }
  .l_footer_service_wrap._catering .l_service_wrap .c_service_list .sp-first, .l_footer_service_wrap._rsc .l_service_wrap .c_service_list .sp-first, .l_footer_service_wrap._rievenhouse .l_service_wrap .c_service_list .sp-first, .l_footer_service_wrap._restaurant .l_service_wrap .c_service_list .sp-first {
    width: 25.3333333333vw;
  }
}
.l_footer_service_wrap._catering .l_service_wrap .c_service_list .c_m_text a, .l_footer_service_wrap._rsc .l_service_wrap .c_service_list .c_m_text a, .l_footer_service_wrap._rievenhouse .l_service_wrap .c_service_list .c_m_text a, .l_footer_service_wrap._restaurant .l_service_wrap .c_service_list .c_m_text a {
  color: #FFF;
  display: inline-block;
  padding-bottom: var(--size-15);
}

.l_footer_service_wrap._catering .l_service_wrap .c_service_list + .c_service_list, .l_footer_service_wrap._rsc .l_service_wrap .c_service_list + .c_service_list, .l_footer_service_wrap._rievenhouse .l_service_wrap .c_service_list + .c_service_list, .l_footer_service_wrap._restaurant .l_service_wrap .c_service_list + .c_service_list {
  margin-left: var(--size-30);
}

@media screen and (max-width: 768px) {
  .l_footer_service_wrap._catering .l_service_wrap .c_service_list + .c_service_list, .l_footer_service_wrap._rsc .l_service_wrap .c_service_list + .c_service_list, .l_footer_service_wrap._rievenhouse .l_service_wrap .c_service_list + .c_service_list, .l_footer_service_wrap._restaurant .l_service_wrap .c_service_list + .c_service_list {
    margin-left: 0;
  }
}
.l_footer_service_wrap._rsc .l_service_wrap .c_service_list .c_m_text a {
  color: #2C2C2C;
}

.l_footer_service_wrap._rsc .l_service_wrap .c_service_list .c_link._blank:after {
  background-image: url(../../img/cmn/icon_blank_white.svg);
}

.l_footer_service_wrap._rsc .c_catering_footer_navi li:nth-child(odd) {
  width: 100%;
}

.l_footer_service_wrap._rsc .c_catering_footer_navi._typeCol2 li {
  width: 50%;
}

.l_footer_service_wrap._rievenhouse .c_service_list .c_catering_footer_navi li:last-child {
  width: auto;
}

.l_footer_service_wrap._restaurant .l_service_wrap .c_service_list .c_m_text a {
  color: #0B0B0B;
}

.l_footer_service_wrap._restaurant .c_service_list .c_catering_footer_navi li:last-child {
  width: auto;
}

@media screen and (max-width: 768px) {
  .l_footer_service_wrap {
    width: 100%;
  }
}
.l_footer_service_wrap .heading {
  text-align: left;
  margin-bottom: var(--size-15);
  line-height: 1;
}

@media screen and (max-width: 768px) {
  .l_footer_service_wrap .heading {
    margin-bottom: var(--size-30);
  }
}
.l_footer_service_wrap .l_service_wrap {
  display: flex;
}

@media screen and (max-width: 768px) {
  .l_footer_service_wrap .l_service_wrap {
    display: block;
  }
}
.l_footer_service_wrap .l_service_wrap .c_service_list {
  flex-shrink: 1;
  text-align: left;
}

.l_footer_service_wrap .l_service_wrap .c_service_list li a {
  font-size: 1.4rem;
  padding-bottom: 7px;
  display: inline-flex;
}

@media screen and (max-width: 768px) {
  .l_footer_service_wrap .l_service_wrap .c_service_list li a {
    padding-bottom: var(--size-30);
    display: flex;
  }
}
.l_footer_service_wrap .l_service_wrap .c_service_list .c_m_text a {
  font-size: 1.6rem;
  font-weight: 600;
  padding-bottom: var(--size-10);
}

@media screen and (max-width: 768px) {
  .l_footer_service_wrap .l_service_wrap .c_service_list .c_m_text a {
    padding-bottom: var(--size-30);
  }
}
.l_footer_service_wrap .l_service_wrap .c_service_list._col02 {
  display: flex;
  flex-wrap: wrap;
  width: 310px;
}

.l_footer_service_wrap .l_service_wrap .c_service_list._col02 li {
  width: 50%;
}

.l_footer_service_wrap .l_service_wrap .c_service_list._col02 li:nth-child(2n+3) {
  padding-left: var(--size-10);
}

.l_footer_service_wrap .l_service_wrap .c_service_list._col02 .c_m_text {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .l_footer_service_wrap .l_service_wrap .c_service_list._rs .c_link {
    font-size: 4.2666666667vw;
  }
}
.l_footer_service_wrap .l_service_wrap .c_service_list + .c_service_list {
  margin-left: var(--size-40);
}

@media screen and (max-width: 768px) {
  .l_footer_service_wrap .l_service_wrap .c_service_list + .c_service_list {
    margin-left: 0;
    margin-top: var(--size-30);
  }
}
.l_footer_service_wrap .l_service_wrap .c_service_list_box {
  margin-left: auto;
}

.l_footer_service_wrap .l_service_wrap .c_service_list_box .c_service_list + .c_service_list {
  margin-left: 0;
  margin-top: var(--size-30);
}

.c_catering_footer_navi {
  display: flex;
  flex-wrap: wrap;
}

.c_catering_footer_navi li:nth-child(odd) {
  width: 276px;
}

@media screen and (max-width: 768px) {
  .c_catering_footer_navi li:nth-child(odd) {
    width: 49.8666666667vw;
  }
}
@media screen and (max-width: 768px) {
  .c_catering_footer_navi li:nth-child(1) {
    order: 1;
  }
  .c_catering_footer_navi li:nth-child(2) {
    order: 3;
  }
  .c_catering_footer_navi li:nth-child(3) {
    order: 2;
  }
  .c_catering_footer_navi li:nth-child(4) {
    order: 4;
  }
  .c_catering_footer_navi li:nth-child(5) {
    order: 5;
  }
}
.c_footer_shop_list {
  display: flex;
  margin-top: var(--size-30);
}

.c_footer_shop_list._rievenhouse {
  margin-top: var(--size-60);
}

@media screen and (max-width: 768px) {
  .c_footer_shop_list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--size-30) var(--size-30);
  }
}
.c_footer_shop_list .c_item + .c_item {
  margin-left: var(--size-25);
}

@media screen and (max-width: 768px) {
  .c_footer_shop_list .c_item + .c_item {
    margin-left: 0;
  }
}
.c_footer_shop_list .c_text {
  font-size: 1.2rem;
  margin-top: var(--size-10);
}

@media screen and (max-width: 768px) {
  .c_footer_shop_list .c_text {
    font-size: 2.6666666667vw;
  }
}
.c_copy_right {
  text-align: center;
  background: #0B0B0B;
  font-size: 1.4rem;
  color: #FFFFFF;
  padding: var(--size-15) 0;
}

@media screen and (max-width: 768px) {
  .c_copy_right {
    font-size: 3.7333333333vw;
  }
}
.c_copy_right._catering {
  font-family: "Noto Sans JP", sans-serif;
}

.c_copy_right._rievenhouse {
  font-family: "Noto Sans JP", sans-serif;
  background: #000;
}

.c_copy_right._rsc {
  background: #5D3500;
  font-family: "Noto Sans JP", sans-serif;
}

/*====================================================================
 SLIDER CSS
====================================================================*/
.slider {
  font-size: 0;
  position: relative;
}

.ani_box_line {
  position: relative;
  transition: all 0.3s;
  outline: none;
}

.ani_box_line:before, .ani_box_line:after {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  z-index: 2;
  content: "";
  transition: all 0.3s;
}

@media screen and (max-width: 768px) {
  .ani_box_line:before, .ani_box_line:after {
    display: none;
  }
}
.ani_box_line:before {
  border-top: 1px solid #A58457;
  border-bottom: 1px solid #A58457;
  transform: scale(0, 1);
}

.ani_box_line:after {
  border-right: 1px solid #A58457;
  border-left: 1px solid #A58457;
  transform: scale(1, 0);
}

.ani_box_line:hover:after, .ani_box_line:hover:before {
  transform: scale(1);
}

.ani_box_line._gray:before {
  border-top: 1px solid #B2B2B2;
  border-bottom: 1px solid #B2B2B2;
}

.ani_box_line._gray:after {
  border-right: 1px solid #B2B2B2;
  border-left: 1px solid #B2B2B2;
}

.ani_box_line._gray:hover:after, .ani_box_line._gray:hover:before {
  height: auto !important;
  bottom: -1px !important;
  background: none !important;
}

.ani_box_line._white:before {
  border-top: 1px solid #FFFFFF;
  border-bottom: 1px solid #FFFFFF;
}

.ani_box_line._white:after {
  border-right: 1px solid #FFFFFF;
  border-left: 1px solid #FFFFFF;
}

.ani_box_line._white:hover:after, .ani_box_line._white:hover:before {
  height: auto !important;
  bottom: -1px !important;
  background: none !important;
}

@keyframes zoomDown {
  0% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
.add-animation {
  animation: zoomDown 6s linear 0s normal both;
}

.slick-dots {
  bottom: -25px;
  left: -150px;
}

@media screen and (max-width: 768px) {
  .slick-dots {
    right: 5.3333333333vw;
    text-align: right;
    left: auto;
  }
}
.slick-dots li button {
  height: 11px;
  width: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  position: relative;
}

.slick-dots li button:before {
  background: #2B2738;
  border-radius: 60px;
  width: 7px;
  height: 7px;
  content: "";
  transition: 0.5s;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  opacity: 0.8;
}

.slick-dots li.slick-active button:before {
  background: #2B2738;
  border-radius: 60px;
  width: 11px;
  height: 11px;
  content: "";
  transition: 0.5s;
  opacity: 1;
}

.slick-dots li {
  margin: 0;
  width: 11px;
  height: 11px;
}

.slick-dots li + li {
  margin-left: var(--size-30);
}

.slick-slide {
  cursor: pointer;
}

/*====================================================================
 PAGE TOP CSS
====================================================================*/
.c_page_top a {
  position: fixed;
  display: block;
  width: var(--size-40);
  height: var(--size-40);
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0B0B0B;
  right: var(--size-20);
  bottom: var(--size-40);
  z-index: 999;
}

@media screen and (max-width: 768px) {
  .c_page_top a {
    width: var(--size-80);
    height: var(--size-80);
  }
}
.c_page_top a:before {
  content: "";
  display: block;
  background-image: url("../../img/cmn/icon_page_top_arrow.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: var(--size-20);
  height: var(--size-10);
}

@media screen and (max-width: 768px) {
  .c_page_top a:before {
    width: var(--size-40);
    height: var(--size-20);
  }
}
.c_page_top._catering a {
  background: #2B2738;
}

.c_page_top._rsc a {
  background: #5D3500;
}

.c_page_top._rsc a:before {
  content: "";
  display: block;
  background-image: url("../../img/cmn/icon_page_top_arrow.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.c_page_top._rievenhouse a {
  background: #334644;
}

/*====================================================================
 MODAL IMAGE CSS
====================================================================*/
.modaal-image .modaal-close {
  top: -50px;
  right: -55px;
  position: absolute;
}

@media screen and (max-width: 768px) {
  .modaal-image .modaal-close {
    top: -26.6666666667vw;
    right: 10.6666666667vw;
  }
}
.modaal-close {
  width: auto;
  height: auto;
}

.modaal-close:before, .modaal-close:after {
  width: 2px;
  height: 37px;
}

.modaal-image .modaal-container {
  max-width: 896px;
}

.modaal-close:focus, .modaal-close:hover {
  background: none;
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #FFF;
}

@media only screen and (max-width: 1140px) {
  .modaal-image .modaal-inner-wrapper {
    padding-left: 10.2489019034vw;
    padding-right: 10.2489019034vw;
  }
}
@media only screen and (max-width: 1140px) and (max-width: 768px) {
  .modaal-image .modaal-inner-wrapper {
    padding-left: var(--size-40);
    padding-right: var(--size-40);
  }
}
@media screen and (max-width: 768px) {
  .modaal-gallery-item img {
    height: 88vw;
    object-fit: cover;
  }
}
.modaal-video .modaal-container {
  max-width: 900px;
  background-color: transparent;
  box-shadow: none;
}

.modaal-video .modaal-close {
  top: -95px;
  right: 40px;
  position: absolute;
}

@media screen and (max-width: 768px) {
  .modaal-video .modaal-close {
    top: -26.6666666667vw;
    right: 10.6666666667vw;
  }
}
.modaal-video .modaal-video-wrap {
  margin: auto;
}

.modaal-video .modaal-inner-wrapper {
  padding: 0;
}

@media screen and (max-width: 768px) {
  .modaal-video .modaal-inner-wrapper {
    padding: 0 10px;
  }
}
.modaal-video .modaal-video-container {
  max-width: 900px;
  max-height: 100%;
  height: 100%;
  padding-bottom: 0;
}

.modaal-video .modaal-video-container iframe,
.modaal-video .modaal-video-container object,
.modaal-video .modaal-video-container embed {
  position: static;
  aspect-ratio: 16/9;
}

/*====================================================================
 Accordion-title CSS
====================================================================*/
@media screen and (max-width: 768px) {
  .accordion-title {
    position: relative;
    cursor: pointer;
  }
  .accordion-title:before, .accordion-title:after {
    position: absolute;
    content: "";
    width: var(--size-50);
    height: 1px;
    background-color: #A58457;
    transform: translate(-50%, -50%);
    top: 50%;
    right: 0;
    transition: 0.3s;
  }
  .accordion-title:after {
    transform: translate(-50%, -50%) rotate(90deg);
  }
  .accordion-title.close:before {
    transform: rotate(45deg);
    right: var(--size-20);
  }
  .accordion-title.close:after {
    transform: rotate(-45deg);
    right: var(--size-20);
  }
}